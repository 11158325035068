const timeZones = [
  { value: "+00:00", label: "GMT" },
  { value: "-01:00", label: "GMT-1" },
  { value: "-02:00", label: "GMT-2 Horário de Fernando de Noronha" },
  { value: "-03:00", label: "GMT-3 Horário de Brasília" },
  { value: "-04:00", label: "GMT-4 Horário da Amazônia" },
  { value: "-05:00", label: "GMT-5 Horário do Acre" },
  { value: "-06:00", label: "GMT-6" },
  { value: "-07:00", label: "GMT-7" },
  { value: "-08:00", label: "GMT-8" },
  { value: "-09:00", label: "GMT-9" },
  { value: "-10:00", label: "GMT-10" },
  { value: "-11:00", label: "GMT-11" },
  { value: "-12:00", label: "GMT-12" },
  { value: "+01:00", label: "GMT+1" },
  { value: "+02:00", label: "GMT+2" },
  { value: "+03:00", label: "GMT+3" },
  { value: "+04:00", label: "GMT+4" },
  { value: "+05:00", label: "GMT+5" },
  { value: "+06:00", label: "GMT+6" },
  { value: "+07:00", label: "GMT+7" },
  { value: "+08:00", label: "GMT+8" },
  { value: "+09:00", label: "GMT+9" },
  { value: "+10:00", label: "GMT+10" },
  { value: "+11:00", label: "GMT+11" },
  { value: "+12:00", label: "GMT+12" }
];

export default timeZones;