import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Form, Modal, Row, Col, Tabs, Table, Button, Tooltip } from "antd";

import moment from "moment";

import { timeCardService } from "../../../redux/services";
import ModalMap from "./ModalMap";
import ModalSelfie from "./ModalSelfie";

class ModalDetails extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			mapModalVisible: false,
			selfieModalVisible: false,
			data: [],
		}
	}

	mapOpen = (latitude, longitude, dataHora, fora_perimetro) => {
		this.setState({ mapModalVisible: true });

		// On open screen
		this.mapScreen.onOpen(latitude, longitude, dataHora, fora_perimetro);
	};

	mapOnClose = () => {
		this.setState({ mapModalVisible: false });
	}

	mapOnComplete = () => {
		this.setState({ mapModalVisible: false });
	}

	selfieOpen = (avatar, data) => {
		this.setState({ selfieModalVisible: true });

		// On open screen
		this.selfieScreen.onOpen(avatar, data);
	};

	selfieOnClose = () => {
		this.setState({ selfieModalVisible: false });
	}

	selfieOnComplete = () => {
		this.setState({ selfieModalVisible: false });
	}

	resetFields = () => {
		this.setState({
			data: [],
		});
	};

	fetchBatidas = () => {
		this.setState({
			isLoading: true,
		});

		timeCardService.getDetalhesPonto({
			cartao_ponto_id: this.props.cartaoPontoId,
		})
		.then((response) => {
			this.formatBatidas(response.data.data);
			this.formatDeletados(response.data.deletados)
			this.formatDuplicados(response.data.duplicados)
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	}

	formatBatidas = (batidas) => {
		let dataBatidas = [];

		batidas.forEach((batida) => {
			if(batida.ponto_registro_entrada) {
				dataBatidas.push(batida.ponto_registro_entrada);
			}
			if(batida.ponto_registro_saida) {
				dataBatidas.push(batida.ponto_registro_saida);
			}
			if(batida.ponto_ajuste_entrada) {
				dataBatidas.push(batida.ponto_ajuste_entrada);
			}
			if(batida.ponto_ajuste_saida) {
				dataBatidas.push(batida.ponto_ajuste_saida);
			}
		});

		this.setState({
			data: dataBatidas,
			isLoading: false,
		});
	}

	formatDeletados = (deletados) => {
		const { data } = this.state;

		let dataDeletados = [];

		deletados.forEach((batida) => {
			dataDeletados.push(batida);
		});

		this.setState({
			data: [...data, ...dataDeletados],
			isLoading: false,
		});
	}

	formatDuplicados = (duplicados) => {
		const { data } = this.state;

		let dataDuplicados = [];

		duplicados.forEach((batida) => {
			dataDuplicados.push(batida);
		});

		this.setState({
			data: [...data, ...dataDuplicados],
			isLoading: false,
		});
	}

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onOpen = () => {
		this.fetchBatidas();
	}

	render() {
		const { visible, empresa, funcionario } = this.props;

		const { isLoading, data } = this.state;

		const columns = [
			{
				title: "",
				dataIndex: "",
				key: "alerts",
				render: (_, { online_offline, fora_perimetro }) => (
					<Fragment>
						{online_offline === 0 && (
							<Tooltip placement="top" title="Batida offline">
								<i className="fa fa-exclamation fa-2xs" style={{ color: '#fa7900' }}></i>
							</Tooltip>
						)}
						{fora_perimetro === 1 && (
							<Tooltip placement="top" title="Batida fora do perimetro">
								<i className="fa fa-location fa-2xs" style={{ marginLeft: 10, color: '#ca3f00' }}></i>
							</Tooltip>
						)}
					</Fragment>
				)
			},
			{
				title: "Tipo",
				dataIndex: "tipo",
				key: "tipo",
			},
			{
				title: "Data",
				dataIndex: "data",
				key: "data",
				sorter: (a, b) => moment(a.data).unix() - moment(b.data).unix(),
				defaultSortOrder: 'ascend',
				render: (_, { data }) => {
					return (
						<div>{moment(data).format('DD/MM/YYYY HH:mm:ss')}</div>
					)
				}
			},
			{
				title: "Motivo",
				dataIndex: "motivo",
				key: "motivo",
			},
			{
				title: "Origem",
				dataIndex: "origem",
				key: "origem",
				render: (_, { origem, avatar, latitude, longitude, data, fora_perimetro }) => {
					const avatarExistS3 = 'prod-byponto-files.s3.us-east-1.amazonaws.com';
					return (
						<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
							{origem ?? "-"}
							<Row gutter={5}>
							{
								latitude && longitude && (
									<Col span={12}>
										<Button
											ghost
											type="primary"
											shape="circle"
											danger={fora_perimetro}
											icon={<i className="fal fa-map"></i>}
											onClick={() => this.mapOpen(latitude, longitude, data, fora_perimetro)}
										/>
									</Col>
								)
							}
							{
								avatar && avatar.includes(avatarExistS3) && (
									<Col span={12}>
										<Button
											ghost
											type="primary"
											shape="circle"
											icon={<i className="fal fa-portrait"></i>}
											onClick={() => this.selfieOpen(avatar, data)}
										/>
									</Col>
								)
							}
							</Row>
						</div>
					)
				}
			},
		];

		return (
			<Modal
				visible={visible}
				title={`Detalhes do cartão ponto [${moment(this.props.dataDia).format("DD/MM/YYYY")}]`}
				wrapClassName="modal-scroll"
				destroyOnClose={true}
				width={680}
				onCancel={this.onClose}
				centered
				footer={null}
			>
				<Form layout="vertical">
					<Row gutter={16}>
						<Col xs={24} md={12}>
							<Form.Item label="Empresa">
								{empresa ?? "-"}
							</Form.Item>
						</Col>
						<Col xs={24} md={12}>
							<Form.Item label="Funcionário">
								{funcionario ?? "-"}
							</Form.Item>
						</Col>
					</Row>
					<Tabs defaultActiveKey="batidas">
						<Tabs.TabPane forceRender tab="Batidas" key="batidas">
							<Table className="table-detalhe-batida" loading={{ spinning: isLoading, indicator: <i className="fad fa-spinner-third fa-spin fa-2x" /> }} rowKey="uuid" locale={{ emptyText: 'Nenhuma justificativa cadastrada.' }} columns={columns} dataSource={data ?? []} pagination={false} />
						</Tabs.TabPane>
					</Tabs>
				</Form>
				<ModalMap
					ref={el => this.mapScreen = el}
					visible={this.state.mapModalVisible}
					onComplete={this.mapOnComplete}
					onClose={this.mapOnClose}
				/>
				<ModalSelfie
					ref={el => this.selfieScreen = el}
					visible={this.state.selfieModalVisible}
					onComplete={this.selfieOnComplete}
					onClose={this.selfieOnClose}
					dataDia={this.props.dataDia}
				/>
			</Modal>
		)
	}
}

export default ModalDetails;