import React from 'react'
import { Card, Col, Form, message, Row, Space, Typography } from 'antd'
const { Text } = Typography
import moment from 'moment/moment'
import { copyTextToClipboard, getTimeZoneLabel } from '../../../../helpers/text'

const IDFace = ({ item }) => {
  const { configuracao, numero_serial_rep, ultimo_nsr, token } = item

  const handleTokenCopyClick = (target) => {
    copyTextToClipboard(target.innerText).then(() => {
      message.info('Token copiado para área de transferência.')
    })
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Dados de comunicação">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Número de série">{numero_serial_rep}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Último NSR">{ultimo_nsr}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="IP">{configuracao.ip}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Porta TCP/IP">
              {configuracao.porta_tcp_ip}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Gateway">{configuracao.gateway ?? '-'}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Máscara de sub rede">
              {configuracao.mascara_sub_rede ?? '-'}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Usuário">{configuracao.usuario}</Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Tipo de comunicação">
              {configuracao.tipo_documento}
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Outros">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Token">
              <Text
                onClick={({ target }) => handleTokenCopyClick(target)}
                style={{ cursor: 'pointer' }}
              >
                {token}
              </Text>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Fuso horário">
              {getTimeZoneLabel[configuracao.fuso_horario]}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Data e horário">
              {configuracao.data_hora
                ? moment(configuracao.data_hora).format('DD/MM/YYYY HH:mm')
                : '-'}
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Space>
  )
}

export default IDFace
