import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import axios from 'axios'
import {
  Alert,
  Col,
  Form,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
  Input
} from 'antd'

import { UIDrawerForm } from './../../components'
import moment from 'moment'

import {
  equipmentsService,
  companiesService,
  departmentsService,
  employeesService,
} from '../../redux/services'

import { API_ERRO_TYPE_CANCEL } from '../../config/general'

import Primme from './components/create/Primme'
import IDClass from './components/create/IDClass'
import IDFace from './components/create/IDFace'
import ADV from './components/create/ADV'
import IDX from './components/create/IDX'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

const EQUIPAMENTS = {
  Primme: Primme,
  IDClass: IDClass,
  'Rep IDClass': IDClass,
  IDFace: IDFace,
  ADV: ADV,
  IDX: IDX,
  'Rep IDX': IDX,
};

class Create extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isSending: false,
      equipments: [],
      equipmentsIsLoading: false,
      models: [],
      brands: [],
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
      model: '',
      communicationType: '',
    }

    this._axiosCancelEquipmentsToken = null
    this._axiosCancelCompaniesToken = null
    this._axiosCancelDepartmentsToken = null
    this._axiosCancelEmployeesToken = null
  }

  fetchEquipmentsFromAdmin = () => {
    if (this._axiosCancelEquipmentsToken) {
      this._axiosCancelEquipmentsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelEquipmentsToken = axios.CancelToken.source()

    this.setState({
      equipmentsIsLoading: true,
    })

    equipmentsService
      .getEquipmentsFromAdmin({
        orderBy: 'modelo:asc',
        cancelToken: this._axiosCancelEquipmentsToken.token,
      })
      .then((response) => {
        this.setState({
          equipmentsIsLoading: false,
          equipments: response.data,
          brands: Array.from(new Set(response.data.map((item) => item.marca))),
          // models: Array.from(new Set(response.data.map((item) => item.modelo))),
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          equipmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchCompanies = () => {
    if (this._axiosCancelCompaniesToken) {
      this._axiosCancelCompaniesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelCompaniesToken = axios.CancelToken.source()

    this.setState({
      companiesIsLoading: true,
    })

    companiesService
      .getAutocomplete({
        orderBy: 'nome:asc',
        cancelToken: this._axiosCancelCompaniesToken.token,
      })
      .then((response) => {
        this.setState({
          companiesIsLoading: false,
          companies: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          companiesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchDepartments = (value) => {
    if (this._axiosCancelDepartmentsToken) {
      this._axiosCancelDepartmentsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelDepartmentsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        departmentsIsLoading: false,
        departments: [],
      })

      return false
    }

    this.setState({
      departmentsIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelDepartmentsToken.token,
      empresas_ids: this.form.getFieldValue('empresas'),
    }

    departmentsService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          departmentsIsLoading: false,
          departments: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          departmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchEmployees = (value) => {
    if (this._axiosCancelEmployeesToken) {
      this._axiosCancelEmployeesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelEmployeesToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        employeesIsLoading: false,
        employees: [],
      })

      return false
    }

    this.setState({
      employeesIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelEmployeesToken.token,
      empresas_ids: this.form.getFieldValue('empresas'),
      departamentos_ids: this.form
        .getFieldValue('departamentos')
        .includes('todos')
        ? []
        : this.form.getFieldValue('departamentos'),
    }

    employeesService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          employeesIsLoading: false,
          employees: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          employeesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onCompanyChange = (value) => {
    this.setState({
      employees: [],
      employeesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
    })

    this.form.setFieldValue('departamentos', ['todos'])
    this.form.setFieldValue('funcionarios', ['todos'])
  }

  onDepartmentChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'departamentos',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onDepartmentSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('departamentos', ['todos'])
    }
  }

  onEmployeeChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'funcionarios',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onEmployeeSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('funcionarios', ['todos'])
    }
  }

  onOpen = () => {
    if (!this.state.companies.length) {
      this.fetchCompanies()
    }
    if (!this.state.equipments.length) {
      this.fetchEquipmentsFromAdmin()
    }

    setTimeout(() => {
      this.form &&
        this.form.setFieldsValue({
          ativo: 1,
        })
    }, 100)
  }

  resetFields = () => {
    this.setState({
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
    })
  }

  onClose = () => {
    // Reset fields
    this.resetFields()

    // Callback
    this.props.onClose()
  }

  onFinish = (values) => {
    this.setState({
      isSending: true,
    })

    const data = { ...values }

    if (!(data.empresas instanceof Array)) {
      data.empresas = [data.empresas]
    }

    if (!(data.departamentos instanceof Array)) {
      data.departamentos = [data.departamentos]
    }

    if (!(data.funcionarios instanceof Array)) {
      data.funcionarios = [data.funcionarios]
    }

    if (data.departamentos.includes('todos')) {
      data.departamentos = null
    }

    if (data.funcionarios.includes('todos')) {
      data.funcionarios = null
    }

    const modelo = data.modelo

    if (modelo === 'IDClass') {
      if (!!data['configuracao_idclass[data_hora]']) {
        data['configuracao_idclass[data_hora]'] = moment(
          data['configuracao_idclass[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }

      if (
        !!data['configuracao_idclass[inicio_horario_verao]'] &&
        !!data['configuracao_idclass[fim_horario_verao]']
      ) {
        data['configuracao_idclass[inicio_horario_verao]'] = moment(
          data['configuracao_idclass[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_idclass[fim_horario_verao]'] = moment(
          data['configuracao_idclass[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }
    }

    if (modelo === 'IDFace') {
      if (!!data['configuracao_idface[data_hora]']) {
        data['configuracao_idface[data_hora]'] = moment(
          data['configuracao_idface[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }
    }

    if (modelo === 'Primme') {
      if (!!data['configuracao_primme[data_hora]']) {
        data['configuracao_primme[data_hora]'] = moment(
          data['configuracao_primme[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }

      if (
        !!data['configuracao_primme[inicio_horario_verao]'] &&
        !!data['configuracao_primme[fim_horario_verao]']
      ) {
        data['configuracao_primme[inicio_horario_verao]'] = moment(
          data['configuracao_primme[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_primme[fim_horario_verao]'] = moment(
          data['configuracao_primme[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }
    }

    if (modelo === 'ADV') {
      if (!!data['configuracao_adv[data_hora]']) {
        data['configuracao_adv[data_hora]'] = moment(
          data['configuracao_adv[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }
      // TODO: Essa condição vai sair, assim que for ajustado a API, para não seri um campo obrigatório
      if (!data['configuracao_adv[ip_conexao_segura]']) {
        data['configuracao_adv[ip_conexao_segura]'] = '192.168.0.0'
      }
      if (
        !!data['configuracao_adv[inicio_horario_verao]'] &&
        !!data['configuracao_adv[fim_horario_verao]']
      ) {
        data['configuracao_adv[inicio_horario_verao]'] = moment(
          data['configuracao_adv[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_adv[fim_horario_verao]'] = moment(
          data['configuracao_adv[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }
    }

    if (modelo === 'IDX') {
      if (!!data['configuracao_idx[data_hora]']) {
        data['configuracao_idx[data_hora]'] = moment(
          data['configuracao_idx[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }

      if (
        !!data['configuracao_idx[inicio_horario_verao]'] &&
        !!data['configuracao_idx[fim_horario_verao]']
      ) {
        data['configuracao_idx[inicio_horario_verao]'] = moment(
          data['configuracao_idx[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_idx[fim_horario_verao]'] = moment(
          data['configuracao_idx[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }
    }

    equipmentsService
      .create(data)
      .then((response) => {
        this.setState({
          isSending: false,
        })

        // Reset fields
        this.resetFields()

        // Success message
        message.success('Registro cadastrado com sucesso.')

        // Callback
        this.props.onComplete()
      })
      .catch((data) => {
        this.setState({
          isSending: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  handleChangeBrand = (brand) => {
    const { equipments } = this.state

    const models = equipments.filter((item) => item.marca === brand)
    this.setState({
      models: Array.from(new Set(models.map((item) => item.modelo))),
    })

    this.form.setFieldsValue({
      modelo: null,
    })
  }

  handleChangeModel = (model) => {
    this.setState({ model: model })
    if (model !== 'ADV') {
      this.form.setFieldsValue({
        tipo_comunicacao: null,
      })
    }
  }

  handleClearDateTime = () => {
    const { model } = this.state

    if (model === 'IDClass') {
      this.form.setFieldsValue({
        'configuracao_idclass[data_hora]': '',
      })
    }

    if (model === 'IDFace') {
      this.form.setFieldsValue({
        'configuracao_idface[data_hora]': '',
      })
    }

    if (model === 'Primme') {
      this.form.setFieldsValue({
        'configuracao_primme[data_hora]': '',
      })
    }

    if (model === 'ADV') {
      this.form.setFieldsValue({
        'configuracao_adv[data_hora]': '',
      })
    }

    if (model === 'IDX') {
      this.form.setFieldsValue({
        'configuracao_idx[data_hora]': '',
      })
    }
  }

  handleClearTimeZones = () => {
    const { model } = this.state

    if (model === 'IDClass') {
      this.form.setFieldsValue({
        'configuracao_idclass[inicio_horario_verao]': '',
        'configuracao_idclass[fim_horario_verao]': '',
      })
    }

    if (model === 'Primme') {
      this.form.setFieldsValue({
        'configuracao_primme[inicio_horario_verao]': '',
        'configuracao_primme[fim_horario_verao]': '',
      })
    }

    if (model === 'ADV') {
      this.form.setFieldsValue({
        'configuracao_adv[inicio_horario_verao]': '',
        'configuracao_adv[fim_horario_verao]': '',
      })
    }

    if (model === 'IDX') {
      this.form.setFieldsValue({
        'configuracao_idx[inicio_horario_verao]': '',
        'configuracao_idx[fim_horario_verao]': '',
      })
    }
  }

  render() {
    const { visible } = this.props

    const {
      isLoading,
      isSending,
      equipmentsIsLoading,
      models,
      brands,
      companiesIsLoading,
      companies,
      departments,
      employees,
      employeesIsLoading,
      model,
      communicationType,
    } = this.state

    const EquipamentComponent = EQUIPAMENTS[model];

    return (
      <UIDrawerForm
        visible={visible}
        width={800}
        onClose={this.onClose}
        isLoading={isLoading}
        isSending={isSending}
        formId={formId}
        title="Incluir Novo Equipamento"
      >
        <Form
          ref={(el) => (this.form = el)}
          id={formId}
          layout="vertical"
          scrollToFirstError
          onFinish={this.onFinish}
          initialValues={{
            ativo: true,
            departamentos: ['todos'],
            funcionarios: ['todos'],
            'configuracao_idclass[atualizar_data_hora]': false,
            'configuracao_idclass[habilitar_horario_verao]': false,

            'configuracao_idface[atualizar_data_hora]': false,

            'configuracao_primme[atualizar_data_hora]': false,
            'configuracao_primme[habilitar_horario_verao]': false,
            'configuracao_primme[habilitar_conexao_segura]': false,
            'configuracao_primme[desativar_icmp]': false,
            'configuracao_primme[gravar_comunicacao_log]': false,

            'configuracao_adv[desativar_icmp]': false,
            'configuracao_adv[gravar_comunicacao_log]': false,
            'configuracao_adv[habilitar_conexao_segura]': false,
            'configuracao_adv[habilitar_horario_verao]': false,
            'configuracao_adv[atualizar_data_hora]': false,
            
            'configuracao_idx[atualizar_data_hora]': false,
          }}
        >
          <Tabs defaultActiveKey="general">
            <Tabs.TabPane forceRender tab="Info. Gerais" key="general">
              <Row gutter={16}>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="marca"
                    label="Marca"
                    hasFeedback
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      ref={(el) => (this.marca = el)}
                      filterOption={false}
                      allowClear
                      placeholder="Selecione a marca"
                      notFoundContent={
                        equipmentsIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onChange={this.handleChangeBrand}
                      showSearch
                    >
                      {brands.map((item, index) => (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="modelo"
                    label="Modelo"
                    hasFeedback
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      ref={(el) => (this.modelo = el)}
                      filterOption={false}
                      allowClear
                      placeholder="Selecione o modelo"
                      notFoundContent={
                        equipmentsIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      onChange={this.handleChangeModel}
                      showSearch
                    >
                      {models.map((item, index) => (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="tipo_comunicacao"
                    label="Comunicação"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (typeof option.children === 'string'
                          ? option.children
                          : option.children.props.children
                        )
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        this.setState({ communicationType: value })
                      }
                      allowClear
                      placeholder="Selecione o tipo de comunicação"
                      showSearch
                    >
                      <Select.Option value="tcp_ip">TCP/IP</Select.Option>
                      <Select.Option value="pendrive">Pendrive</Select.Option>
                      {model === 'ADV' && (
                        <Select.Option value="wifi">Wi-fi</Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col xs={24} md={24}>
                <Form.Item
                  name="descricao"
                  label="Descrição"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Input placeholder="Descrição" />
                </Form.Item>
              </Col>
              <Form.Item
                name="empresas"
                label="Empresa"
                hasFeedback
                rules={[{ required: true, message: 'Campo obrigatório.' }]}
              >
                <Select
                  ref={(el) => (this.empresas = el)}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (typeof option.children === 'string'
                      ? option.children
                      : option.children.props.children
                    )
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  placeholder="Selecione a(s) empresa(s)"
                  notFoundContent={
                    companiesIsLoading ? (
                      <Spin
                        indicator={
                          <i className="fad fa-spinner-third fa-spin" />
                        }
                      />
                    ) : null
                  }
                  onChange={this.onCompanyChange}
                  showSearch
                >
                  {companies.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="departamentos"
                label="Departamento"
                rules={[{ required: true, message: 'Campo obrigatório.' }]}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (typeof option.children === 'string'
                      ? option.children
                      : option.children.props.children
                    )
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  placeholder="Selecione o(s) departamento(s)"
                  notFoundContent={
                    employeesIsLoading ? (
                      <Spin
                        indicator={
                          <i className="fad fa-spinner-third fa-spin" />
                        }
                      />
                    ) : null
                  }
                  onSearch={this.fetchDepartments}
                  onChange={this.onDepartmentChange}
                  onSelect={this.onDepartmentSelect}
                  showSearch
                >
                  <Select.Option value="todos">Todos</Select.Option>
                  {departments.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="funcionarios"
                label="Funcionário"
                rules={[{ required: true, message: 'Campo obrigatório.' }]}
              >
                <Select
                  mode="multiple"
                  filterOption={false}
                  allowClear
                  placeholder="Selecione o(s) funcionário(s)"
                  notFoundContent={
                    employeesIsLoading ? (
                      <Spin
                        indicator={
                          <i className="fad fa-spinner-third fa-spin" />
                        }
                      />
                    ) : null
                  }
                  onSearch={this.fetchEmployees}
                  onChange={this.onEmployeeChange}
                  onSelect={this.onEmployeeSelect}
                  showSearch
                >
                  <Select.Option value="todos">Todos</Select.Option>
                  {employees.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="ativo" label="Ativo" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane
              forceRender
              tab="Configurações"
              key="config"
            >
              {EquipamentComponent && (
                <EquipamentComponent
                  communicationType={communicationType}
                  handleClearDateTime={this.handleClearDateTime}
                  handleClearTimeZones={this.handleClearTimeZones}
                />
              )}
            </Tabs.TabPane>

            <Tabs.TabPane forceRender tab="Funcionários" key="employees">
              <Alert
                message="Salve o item atual parar liberar este conteúdo para edição."
                type="info"
                showIcon
                icon={<i className="fas fa-info-circle" />}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              forceRender
              tab="Exportar Funcionários"
              key="export-employees"
            >
              <Alert
                message="Salve o item atual parar liberar este conteúdo para edição."
                type="info"
                showIcon
                icon={<i className="fas fa-info-circle" />}
              />
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </UIDrawerForm>
    )
  }
}

export default Create
