import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Col, Checkbox, Table, InputNumber, Row, Select, Button, Popconfirm, message } from "antd";

import moment from "moment";

import {
	HourPickerWithMask,
} from "../../components";

class DayTable extends Component {
	static propTypes = {
		values: PropTypes.arrayOf(PropTypes.object),
		tipo_calculo: PropTypes.string,
		tipo_horario: PropTypes.any,
		fixar_tolerancia: PropTypes.bool,
		showOnly: PropTypes.bool,
	}

	constructor(props) {
		super(props);

		this.state = {
			deletedsOriginalTracks: [],
			isLoading: false,
			data     : [],
			deleteds: [],
		};
	}

	componentDidMount() {
		this.formatData('DidMount');
	}

	componentDidUpdate(prevProps) {
		if (prevProps.tipo_horario !== this.props.tipo_horario) {
			this.formatData('DidUpdate');
		}
		if (prevProps.tolerancia_extra !== this.props.tolerancia_extra) {
			this.onChangeTolerancia('extra');
		}
		if (prevProps.tolerancia_falta !== this.props.tolerancia_falta) {
			this.onChangeTolerancia('falta');
		}
		// Ignora se o tipo_calculo for null, pra não executar no primeiro DidUpdate
		if (prevProps.tipo_calculo !== null && prevProps.fixar_tolerancia !== this.props.fixar_tolerancia) {
			this.onResetTolerancia();
		}
	}

	onChangeTolerancia = (type) => {
		const { tolerancia_extra, tolerancia_falta } = this.props;
		let newData = this.state.data;

		let tolerancia = Number(type == 'extra' ? tolerancia_extra : tolerancia_falta);
		if(!isNaN(tolerancia)) {
			const duracao = moment.duration(tolerancia, 'minutes');
			const horas = moment.utc(duracao.asMilliseconds()).format('HH:mm');
	
			newData.forEach((track) => {
				track[`tolerancia_hora_${type}`] = horas;
			});
	
			this.setState({ data: newData });
		}

		this.onChangeTableFields();
	}

	onResetTolerancia = () => {
		let newData = this.state.data;

		newData.forEach((track) => {
			track.tolerancia_hora_extra = "00:10";
			track.tolerancia_hora_falta = "00:10";
		});

		this.setState({ data: newData });

		this.onChangeTableFields();
	}

	formatData = async (update = null) => {
		const { values, tipo_calculo, tipo_horario, showOnly } = this.props;
		let newData = [];

		let tol_extra = null;
		let tol_falta = null;

		if (this.props.fixar_tolerancia) {
			tol_extra = "00:10";
			tol_falta = "00:10";
		} else {
			let duracaoExtra = moment.duration(this.props.tolerancia_extra, 'minutes');
			let horasExtra = moment.utc(duracaoExtra.asMilliseconds()).format('HH:mm');
			tol_extra = horasExtra;

			let duracaoFalta = moment.duration(this.props.tolerancia_falta, 'minutes');
			let horasFalta = moment.utc(duracaoFalta.asMilliseconds()).format('HH:mm');
			tol_falta = horasFalta;
		}

		const criarBatidaPadrao = (sequencia) => ({
			id: Math.floor(Math.random() * 10001),
			sequencia: sequencia,
			entrada: "",
			entrada_auto: false,
			saida: "",
			saida_auto: false,
			carga_horaria: "",
		});

		if (values && values.length < 7 && tipo_horario === 'semanal' && !showOnly && update === 'DidUpdate' ) {
			["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado", "Domingo"]?.forEach((dia, index) => {
				newData.push({
					id: Math.floor(Math.random() * 10001),
					dia: dia,
					tipo: "normal",
					duracao_escala: 0,
					tipo_calculo: tipo_calculo,
					batida: [
						criarBatidaPadrao(1),
						criarBatidaPadrao(2),
						criarBatidaPadrao(3)
					],
					tempo_mais_menos: "",
					somar_total: false,
					tolerancia_hora_extra: tol_extra,
					tolerancia_hora_falta: tol_falta,
					carga_horaria: "",
					fechamento: null,
				});
			});
			
						
			for (const track of values) {
				await this.onRemoveRow(track.id);
			}

			this.setState({ data: newData }, () => {
				this.props.getIntervals(this.state.data);
			});
			return;
		}

		if (values && values.length > 0 && update && tipo_horario === 'escala') {
			let newData = [];
	
			values.forEach((track, index) => {
				let batidas = [];
				if (track.horario_batidas && track.horario_batidas.length > 0) {
					batidas = track.horario_batidas.map((batida) => ({
						id: batida.id,
						acao: "editar",
						sequencia: batida.sequencia,
						entrada: batida.entrada || "",
						entrada_auto: batida.entrada_auto,
						saida: batida.saida || "",
						saida_auto: batida.saida_auto,
						carga_horaria: batida.carga_horaria.substr(0, 5),
					}));
					if (batidas.length === 1) {
						batidas.push(criarBatidaPadrao(batidas.length + 2));
					}
					if (batidas.length === 2) {
						batidas.push(criarBatidaPadrao(batidas.length + 1));
					}
				} else {
					batidas = [1, 2, 3].map(criarBatidaPadrao);
				}
	
				newData.push({
					id: track.id,
					dia: track.dia,
					duracao_escala: track?.duracao_escala ? track.duracao_escala : '',
					acao: "editar",
					tipo: track?.tipo ?? "normal",
					tipo_calculo: track.tipo_calculo,
					deletedsOriginalBatidas: [],
					batida: batidas,
					tempo_mais_menos: track.tempo_mais_menos.substr(0, 5),
					somar_total: (track.somar_total == 1 ? true : false),
					tolerancia_hora_extra: track.tolerancia_hora_extra.substr(0, 5),
					tolerancia_hora_falta: track.tolerancia_hora_falta.substr(0, 5),
					carga_horaria: track.carga_horaria.substr(0, 5),
					fechamento: track.fechamento,
				});
			});
	
			this.setState({
					data: newData,
			}, () => {
					this.props.getIntervals(this.state.data);
			});
			return;
		}	

		if (values && values.length > 0 && update && tipo_horario === 'semanal') {
			const daysOfWeek = ["segunda", "terca", "quarta", "quinta", "sexta", "sabado", "domingo"];
			let weekData = daysOfWeek.map(day => ({
				dia: day,
				batidas: [1, 2, 3].map(criarBatidaPadrao),
				tipo: "normal",
				tipo_calculo: tipo_calculo,
				duracao_escala: 0,
				tempo_mais_menos: "",
				somar_total: false,
				tolerancia_hora_extra: tol_extra,
				tolerancia_hora_falta: tol_falta,
				carga_horaria: "",
				fechamento: null,
			}));

			values.forEach(track => {
			const dayIndex = daysOfWeek.indexOf(track.dia);
			if (dayIndex !== -1) {
				let batidas = [];
				if (track.horario_batidas && track.horario_batidas.length > 0) {
					batidas = track.horario_batidas.map((batida) => ({
						id: batida.id,
						acao: "editar",
						sequencia: batida.sequencia,
						entrada: batida.entrada || "",
						entrada_auto: batida.entrada_auto,
						saida: batida.saida || "",
						saida_auto: batida.saida_auto,
						carga_horaria: batida.carga_horaria.substr(0, 5),
					}));
					if (batidas.length === 1) {
						batidas.push(criarBatidaPadrao(batidas.length + 2));
					}
					if (batidas.length === 2) {
						batidas.push(criarBatidaPadrao(batidas.length + 1));
					}
				} else {
					batidas = [1, 2, 3].map(criarBatidaPadrao);
				}

					weekData[dayIndex] = {
						id: track.id,
						dia: track.dia,
						duracao_escala: track?.duracao_escala ? track.duracao_escala : '',
						acao: "editar",
						tipo: track?.tipo ?? "normal",
						tipo_calculo: track.tipo_calculo,
						deletedsOriginalBatidas: [],
						batida: batidas,
						tempo_mais_menos: track.tempo_mais_menos.substr(0, 5),
						somar_total: (track.somar_total == 1 ? true : false),
						tolerancia_hora_extra: track.tolerancia_hora_extra.substr(0, 5),
						tolerancia_hora_falta: track.tolerancia_hora_falta.substr(0, 5),
						carga_horaria: track.carga_horaria.substr(0, 5),
						fechamento: track.fechamento,
					};
				}
			});

			newData = weekData;
	} else {
			if (tipo_horario == "escala") {
				newData.push({
					id: Math.floor(Math.random() * 10001),
					dia: "escala",
					tipo: "normal",
					tipo_calculo: tipo_calculo,
					duracao_escala: 0,
					batida: [
						criarBatidaPadrao(1),
						criarBatidaPadrao(2),
						criarBatidaPadrao(3)
					],
					tempo_mais_menos: "",
					somar_total: false,
					tolerancia_hora_extra: tol_extra,
					tolerancia_hora_falta: tol_falta,
					carga_horaria: "",
					fechamento: null,
				});
			} else {
				["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado", "Domingo"]?.forEach((dia, index) => {
					newData.push({
						id: Math.floor(Math.random() * 10001),
						dia: dia,
						tipo: "normal",
						duracao_escala: 0,
						tipo_calculo: tipo_calculo,
						batida: [
							criarBatidaPadrao(1),
							criarBatidaPadrao(2),
							criarBatidaPadrao(3)
						],
						tempo_mais_menos: "",
						somar_total: false,
						tolerancia_hora_extra: tol_extra,
						tolerancia_hora_falta: tol_falta,
						carga_horaria: "",
						fechamento: null,
					});
				});
			}
		}

		this.setState({
			data: newData,
		}, () => {
			this.props.getIntervals(this.state.data);
		});
	}

	onCreateRow = () => {
		const { data } = this.state;
		const { tipo_calculo } = this.props;
		
		const POSITION_OF_LAST_ROW = data.length - 1;
		const LENGTH_OF_BATIDAS = data[POSITION_OF_LAST_ROW].batida.length;

		const BATIDA_DEFAULT = {
			entrada: "",
			entrada_auto: false,
			saida: "",
			saida_auto: false,	
			carga_horaria: "",
		}

		const batidas = Array.from({ length: LENGTH_OF_BATIDAS }).fill(BATIDA_DEFAULT);
		const batida = batidas.map((item, index) => ({
			id: Math.floor(Math.random() * 10001),
			sequencia: index + 1,
			...item,
		}))

		this.setState({
			data: [
				...this.state.data,
				{
					id: Math.floor(Math.random() * 10001),
					dia: 'escala',
					tipo: "normal",
					tipo_calculo: tipo_calculo,
					batida,
					tempo_mais_menos: "00:00",
					somar_total: false,
					tolerancia_hora_extra: "00:10",
					tolerancia_hora_falta: "00:10",
					carga_horaria: "",
					fechamento: 0,
				},
			],
		}, () => this.onChangeTableFields());
	}

	onRemoveRow = (id) => {
    return new Promise((resolve) => {
			const newData = this.state.data.filter(item => item.id !== id);
			const deletedsItem = this.state.data.find(item => item.id === id);
			const newDeleteds = [...this.state.deleteds, deletedsItem];
			this.setState({ data: newData, deleteds: newDeleteds }, () => {
				resolve();
			});
    });
	}

	onClearRow = (index) => {
		let tracks = [...this.state.data];

		tracks[index].tempo_mais_menos = "";
		tracks[index].somar_total = false;
		tracks[index].carga_horaria = "";
		tracks[index].fechamento = null;

		tracks[index].batida.forEach((batida) => {
			batida.entrada = "";
			batida.saida = "";
			batida.carga_horaria = "";
		});

		this.setState({ data: tracks }, () => this.onChangeTableFields());
	}

	onReplaceTrack = (index) => {
		let newTracks = [...this.state.data];

		newTracks.forEach((track, indexTracks) => {
			if (indexTracks > index) {
				track.tempo_mais_menos = newTracks[index].tempo_mais_menos;
				track.somar_total = newTracks[index].somar_total;
				track.tolerancia_hora_extra = newTracks[index].tolerancia_hora_extra;
				track.tolerancia_hora_falta = newTracks[index].tolerancia_hora_falta;
				track.carga_horaria = newTracks[index].carga_horaria;
				track.fechamento = newTracks[index].fechamento;
				track.duracao_escala = newTracks[index].duracao_escala;

				track.batida.forEach((batida, indexBatida) => {
					batida.entrada = newTracks[index].batida[indexBatida].entrada;
					batida.saida = newTracks[index].batida[indexBatida].saida;
				});
			}
		});

		this.setState({ data: newTracks }, () => this.onChangeTableFields());
	}

	onAddBatida = () => {
		const { data } = this.state;
		let tracks = data;

		tracks.forEach((track) => {
			track.batida.push({
				id: Math.floor(Math.random() * 10001),
				sequencia: track.batida.length + 1,
				acao: "criar",
				entrada: "",
				entrada_auto: false,
				saida: "",
				saida_auto: false,
				carga_horaria: "",
			});
		});

		this.setState({ data: tracks }, () => {
			this.props.getIntervals(this.state.data);
		}, () => this.onChangeTableFields());
	}

	onRemoveBatida = () => {
    const { data } = this.state;
    let tracks = data;
    let errorOccurred = false;

    for (let i = 0; i < tracks.length; i++) {
			if (tracks[i].batida.length === 3) {
				message.error('Não é possivel deletar batida.');
				errorOccurred = true;
				break;
			}

			let batida = tracks[i].batida[tracks[i].batida.length - 1];
			const tempo_mais_menos = tracks[i].tempo_mais_menos
			const somar_total = tracks[i].somar_total
			let cargaValue = moment.duration(0);

			tracks[i].batida.pop();

			tracks[i].batida?.forEach((bat, index) => {
				// Crie objetos Moment.js a partir das strings de hora de entrada e saída
				const entrada = moment(bat.entrada, 'HH:mm');
				const saida = moment(bat.saida, 'HH:mm');

				// Calcule a duração entre entrada e saída
				const duracao = moment.duration(saida.diff(entrada));

				// Adicione a duração à variável de carga
				cargaValue.add(duracao);
			});

			if (tempo_mais_menos && somar_total) {
				cargaValue.add(moment.duration(tempo_mais_menos));
			}

			tracks[i].carga_horaria = moment.utc(cargaValue.asMilliseconds()).format('HH:mm');
			tracks[i].batida[0].carga_horaria = moment.utc(cargaValue.asMilliseconds()).format('HH:mm');

			if (batida.acao !== "criar") {
				batida = { ...batida, acao: "deletar" };
				tracks[i].deletedsOriginalBatidas.push(batida);
			}
    }

    if (!errorOccurred) {
			this.setState({ data: tracks }, () => {
				this.props.getIntervals(this.state.data);
			}, () => this.onChangeTableFields());
    }
	}

	onChangePreAssinalar = (e, type, id) => {
		const { data } = this.state;
		let tracks = data;

		tracks.forEach((track) => {
			track.batida.forEach((batida) => {
				if (batida.id === id) {
					batida[type + "_auto"] = e.target.checked;
				}
			});
		});

		this.setState({ data: tracks }, () => this.onChangeTableFields());
	}

	onCalcCargaHoraria = (index) => {
		const { data: tracks } = this.state;
		const { batida, tempo_mais_menos, somar_total } = tracks[index];	
		const items = batida?.filter((bat) => bat.entrada !== '' || bat.saida !== '')
		let cargaValue = '';
		if (items.length > 0) {
			cargaValue = moment.duration(0);
			batida?.forEach((bat) => {
				// Crie objetos Moment.js a partir das strings de hora de entrada e saída
				const entrada = moment(bat.entrada, 'HH:mm');
				const saida = moment(bat.saida, 'HH:mm');
	
				// Calcule a duração entre entrada e saída
				const duracao = moment.duration(saida.diff(entrada));
	
				// Adicione a duração à variável de carga
				cargaValue.add(duracao);
			});
	
			if (tempo_mais_menos && somar_total) {
				cargaValue.add(moment.duration(tempo_mais_menos));
			}

			cargaValue = moment.utc(cargaValue.asMilliseconds()).format('HH:mm');
		}

		const newTracks = [...tracks];
		newTracks[index].carga_horaria = cargaValue;
		newTracks[index].batida[0].carga_horaria = cargaValue;

		this.setState({ data: newTracks }, () => this.onChangeTableFields());
	}

	formatDia = (dia) => {
		let dias = {
			segunda: "Segunda",
			terca: "Terça",
			quarta: "Quarta",
			quinta: "Quinta",
			sexta: "Sexta",
			sabado: "Sábado",
			domingo: "Domingo",
			"Segunda": "Segunda",
			"Terça": "Terça",
			"Quarta": "Quarta",
			"Quinta": "Quinta",
			"Sexta": "Sexta",
			"Sábado": "Sábado",
			"Domingo": "Domingo",
		}

		return dias[dia];
	}

	formatDiaByIndex = (index) => {
		let dias = {
			0: "Segunda",
			1: "Terça",
			2: "Quarta",
			3: "Quinta",
			4: "Sexta",
			5: "Sábado",
			6: "Domingo",
		}

		return dias[index];
	};

	handleKeyUp = (evt, index, i, position) => {
    const { tipo_horario } = this.props;
    const key = evt.key;
    const value = evt.target.value;
    const field = position === 'entrada' ? 'entrada' : 'saida';

    if ((key === 'Backspace' || key === 'Delete') && value === '') {
			const newTracks = [...this.state.data];
			newTracks[index].batida[i][field] = ''; 
			this.setState({ [`${tipo_horario}`]: newTracks }, () => {
				this.onCalcCargaHoraria(index);
			});
		}
	};

	// showPreAssinalar = (i, type, batida) => {
	// 	let show = false;

	// 	if(batida.length > 1) {
	// 		if(i === 0 && type === "saida" || i === batida.length - 1 && type === "entrada" || i > 0 && i < batida.length - 1) {
	// 			show = true;
	// 		}
	// 	}

	// 	return show;
	// }


	onChangeTableFields = () => {
		this.props?.onChange?.();
	}

	getDataForSubmission = () => {
		const { data, deleteds } = this.state;
		const dataForSubmission = data.map(item => ({ ...item }));
		const deletedsForSubmission = deleteds.map(item => ({ ...item, acao: 'deletar' }));
		
		return [...dataForSubmission, ...deletedsForSubmission];
	}

	render() {
		const { data } = this.state;
		const { fixar_tolerancia, tipo_calculo, tipo_horario, showOnly } = this.props;

		let tracks = data;

		let highestNumberOfBatidas = 0;

		tracks?.forEach((track, i) => {
			if (track.batida.length > highestNumberOfBatidas) {
				highestNumberOfBatidas = track.batida.length;
			}
		})

		const columns = [
			{
				className: 'column-actions',
				title: () => {
					return (
						<Fragment>
							<Row gutter={5}>
								<Col span={12}>
									<Popconfirm disabled={showOnly || data[0]?.batida.length == 1} placement="topLeft" title="Deseja excluir essa batida?" onConfirm={() => this.onRemoveBatida()} okText="Sim" cancelText="Não">
										<Button disabled={showOnly || data[0]?.batida.length == 1} danger shape="circle" size="small" icon={<i className="fal fa-trash"></i>} />
									</Popconfirm>
								</Col>
								<Col span={12}>
									<Button disabled={showOnly} onClick={() => this.onAddBatida()} type="secondary" shape="circle" size="small" icon={<i className="fas fa-plus"></i>} />
								</Col>
							</Row>
						</Fragment>
					)
				},
				width: 100,
				fixed: 'left',
				render: (_, { dia }, index) =>
					tipo_horario == 'escala' ? (
						<h4 key={'ciclo_' + (index + 1)}>{`Ciclo ${index + 1}`}</h4>
					) : (
						<h4 key={'dia_' + (dia ?? index)}>{this.formatDia(dia) ?? this.formatDiaByIndex(index)}</h4>
					)
			},
		];

			(tipo_horario === 'escala') &&
			columns.push({
					title: 'Duração escala',
					width: 100,
					render: (_, { duracao_escala, id, tipo }, index) => (
							<InputNumber
									type={"number"}
									key={"tol_extra_" + id}
									value={duracao_escala || 1}
									disabled={showOnly || tipo === "folga" || tipo === "dia_especial"}
									onChange={(value) => {
											const newTracks = [...tracks];
			
											newTracks[index].duracao_escala = value < 1 ? 1 : value;
			
											this.setState({ data: newTracks }, () => this.onChangeTableFields());
									}}
									min={1}
									controls={false}
									style={{ maxWidth: 120 }}
							/>
					),
			});		

		columns.push({
			title: 'Folga/Dia especial',
			dataIndex: '1',
			width: 140,
			key: '1',
			render: (_, { id, tipo }, index) => (
				<Select
					optionFilterProp="children"
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					allowClear
					disabled={showOnly}
					onChange={(value) => {
						const newTracks = [...tracks];

						if(value === "folga") {
							newTracks[index].carga_horaria = "";
							newTracks[index].batida.forEach((batida) => {
								batida.entrada = "";
								batida.saida = "";
								batida.carga_horaria = "";
							});
						}

						if(value === "dia_especial") {
							newTracks[index].carga_horaria = "";
							newTracks[index].batida.forEach((batida) => {
								batida.entrada = "";
								batida.saida = "";
								batida.carga_horaria = "";
							});
						}

						newTracks[index] = { ...newTracks[index], tipo: value }

						this.setState({ data: newTracks }, () => this.onChangeTableFields());
					}}
					value={tipo ?? null}
					style={{ width: "100%" }}
					showSearch
					key={'tipo_excecao_' + id}
				>
					<Select.Option value="normal">Nenhuma</Select.Option>
					<Select.Option value="folga">Folga</Select.Option>
					<Select.Option value="dia_especial">Dia especial</Select.Option>
				</Select>
			),
		},);

		[...Array(highestNumberOfBatidas).keys()]?.forEach((i) => {
			columns.push(
			{
				title: `Entrada ${i + 1}`,
				width: 94,
				className: "text-center-important",
				render: (_, { id, batida, tipo }, index) => {
					return (
						batida[i] ? (
							<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px" }}>
								{/* {this.showPreAssinalar(i, "entrada", batida) && <Checkbox style={{ marginTop: 2 }} key={"pre_assinalar_" + id + "_" + (i + 1)} checked={batida[i]?.entrada_auto} onChange={(e) => this.onChangePreAssinalar(e, "entrada", batida[i].id)} />} */}
								<HourPickerWithMask
									form_={false}
									disabled={showOnly || tipo === "folga" || tipo === "dia_especial"}
									onChange={(evt) => {
										const newTracks = [...tracks];
										
										newTracks[index].batida[i].entrada = evt.target.value

										this.setState({ [`${tipo_horario}`]: newTracks }, () => {
											this.onCalcCargaHoraria(index)
										})
									}}
									onKeyUp={(evt) => this.handleKeyUp(evt, index, i, 'entrada')}
									value={batida[i]?.entrada}
									style={{ maxWidth: "78px" }}
								/>
							</div>
						) : (<></>)
					)
				},
			},
			{
				title: `Saída ${i + 1}`,
				width: 94,
				className: "text-center-important",
				render: (_, { id, batida, tipo }, index) => {
					return (
						batida[i] ? (
							<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px" }}>
								{/* {this.showPreAssinalar(i, "saida", batida) && <Checkbox style={{ marginTop: 2 }} key={"pre_assinalar_" + id + "_" + (i + 1)} checked={batida[i]?.saida_auto} onChange={(e) => this.onChangePreAssinalar(e, "saida", batida[i].id)} />} */}
								<HourPickerWithMask
									form_={false}
									disabled={showOnly || tipo === "folga" || tipo === "dia_especial"}
									onChange={(evt) => {
										const newTracks = [...tracks];

										newTracks[index].batida[i].saida = evt.target.value;

										this.setState({ [`${tipo_horario}`]: newTracks }, () => {
											this.onCalcCargaHoraria(index)
										})
									}}
									onKeyUp={(evt) => this.handleKeyUp(evt, index, i, 'saida')}
									value={batida[i]?.saida}
									style={{ maxWidth: "78px" }}
								/>
							</div>
						) : (<></>)
					)
				},
			})
		});

		columns.push(
			{
				title: 'Tempo +/-',
				dataIndex: 'tempo_mais_menos',
				width: 100,
				key: '3',
				render: (_, { id, tempo_mais_menos, tipo }, index) => (
					<HourPickerWithMask disabled={showOnly || tipo === "folga" || tipo === "dia_especial"} form_={false} key={"tempo_" + id} value={tempo_mais_menos === "00:00" ? "" : tempo_mais_menos}
						onChange={(evt) => {
							const newTracks = [...tracks];

							newTracks[index].tempo_mais_menos = evt.target.value;

							this.setState({ [`${tipo_horario}`]: newTracks }, () => {
								this.onCalcCargaHoraria(index)
							})
						}} />
				),
			},
			{
				title: 'Tol. de Extra',
				dataIndex: 'coluna_perc',
				width: 100,
				key: '4',
				render: (_, { tolerancia_hora_extra, id, tipo }, index) => (
					<HourPickerWithMask 
						key={"tol_extra_" + id} 
						disabled={fixar_tolerancia || showOnly || tipo === "folga" || tipo === "dia_especial" || ['escala', 'semanal'].includes(tipo_horario)} 
						form_={false} 
						value={tolerancia_hora_extra === "00:00" ? "" : tolerancia_hora_extra}
						onChange={(evt) => {
							const newTracks = [...tracks];

							newTracks[index].tolerancia_hora_extra = evt.target.value;

							this.setState({ [`${tipo_horario}`]: newTracks });

							this.onChangeTableFields();
						}}
					/>
				),
			},
			{
				title: 'Tol. de Falta',
				dataIndex: 'coluna_perc',
				width: 100,
				key: '5',
				render: (_, { id, tolerancia_hora_falta, tipo }, index) => (
					<HourPickerWithMask 
						key={"tol_falta_" + id} 
						disabled={fixar_tolerancia || showOnly || tipo === "folga" || tipo === "dia_especial" || ['escala', 'semanal'].includes(tipo_horario)} 
						form_={false} 
						value={tolerancia_hora_falta === "00:00" ? "" : tolerancia_hora_falta}
						onChange={(evt) => {
							const newTracks = [...tracks];

							newTracks[index].tolerancia_hora_falta = evt.target.value;

							this.setState({ [`${tipo_horario}`]: newTracks });

							this.onChangeTableFields();
						}}
					/>
				),
			},
			{
				title: 'Tipo Cálculo',
				dataIndex: 'coluna_perc',
				width: 180,
				key: '6',
				render: (_, { id }, index) => (
					<Select
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						allowClear
						disabled
						key={"tipo_calc_" + id}
						value={tipo_calculo ?? null}
						placeholder="- Selecione -"
						style={{ width: "100%" }}
						onChange={() =>	this.onChangeTableFields()}
						showSearch
					>
						<Select.Option value="flexivel">Flexível</Select.Option>
						<Select.Option value="horista">Horista</Select.Option>
						<Select.Option value="intervalo_flexivel">Intervalo Flexível</Select.Option>
						<Select.Option value="normal">Normal</Select.Option>
					</Select>
				),
			},
			{
				title: 'Fechamento',
				dataIndex: 'coluna_perc',
				width: 100,
				key: '7',
				render: (_, { id, fechamento, tipo }, index) => (
					<InputNumber
						type={"number"}
						disabled={showOnly}
						key={"fechamento_" + id}
						value={fechamento == 0 ? null : fechamento}
						onChange={(value) => {
							const newTracks = [...tracks];

							newTracks[index].fechamento = value;

							this.setState({ [`${tipo_horario}`]: newTracks });

							this.onChangeTableFields();
						}}
						min={0}
						controls={false}
						style={{ maxWidth: 120 }}
					/>
				),
			},
			{
				title: 'Somar T',
				dataIndex: '10',
				width: 80,
				key: '10',
				render: (_, { id, somar_total, tipo }, index) => (
					<Checkbox disabled={showOnly || tipo === "folga" || tipo === "dia_especial"} key={"somar_total_" + id} checked={somar_total} onChange={(evt) => {
						const newTracks = [...tracks];

						newTracks[index].somar_total = evt.target.checked;

						this.setState({ [`${tipo_horario}`]: newTracks }, () => {
							this.onCalcCargaHoraria(index);
						})
					}} />
				),
			},
			{
				title: 'Carga',
				dataIndex: '10',
				width: 100,
				key: '10',
				render: (_, { id, carga_horaria, batida, tempo_mais_menos, somar_total, tipo }, index) => {
					return (
						<HourPickerWithMask disabled={true} key={"carga_" + id} form_={false} value={carga_horaria} onChange={(evt) => {
							const newTracks = [...tracks];

							newTracks[index].carga_horaria = evt.target.value;

							this.setState({ [`${tipo_horario}`]: newTracks });

							this.onChangeTableFields();
						}} />
					)
				},
			},
		)

		if(!showOnly) {
			columns.push(
				{
					title: 'Ações',
					dataIndex: 'acoes',
					key: '8',
					width: 120,
					render: (_, { id, tipo }, index) => (
						<Row gutter={5}>
							<Col span={12}>
								<Popconfirm disabled={(index + 1) === this.state.data.length} placement="topRight" title={`Deseja replicar os valores para as faixas abaixo?`} onConfirm={() => this.onReplaceTrack(index)} okText="Sim" cancelText="Não">
									<Button disabled={(index + 1) === this.state.data.length} type="secondary" shape="circle" icon={<i className="fas fa-long-arrow-down"></i>} />
								</Popconfirm>
							</Col>
							{
								tipo_horario == "escala" ? (
									<Col span={12}>
										<Popconfirm 
											placement="topRight" 
											title="Deseja excluir essa faixa?" 
											onConfirm={() => this.onRemoveRow(id)} 
											okText="Sim" 
											cancelText="Não"
											disabled={(index + 1) === this.state.data.length}
										>
											<Button
												disabled={(index + 1) === this.state.data.length}
												danger 
												shape="circle" 
												icon={<i className="fal fa-trash"></i>} 
											/>
										</Popconfirm>
									</Col>
								) :
									(
										<Col span={12}>
											<Popconfirm placement="topRight" title="Deseja limpar os dados dessa faixa?" onConfirm={() => this.onClearRow(index)} okText="Sim" cancelText="Não">
												<Button danger shape="circle" icon={<i className="fal fa-eraser"></i>} />
											</Popconfirm>
										</Col>
									)
							}
						</Row>
					),
				},
			);
		}

		return (
			<Fragment>
				<Table scroll={{ x: 300 }} size="small" columns={columns} dataSource={tracks} rowKey="id" pagination={false} />
				{(!showOnly && tipo_horario == "escala") &&
					(
						<div>
							<Button type="ghost" className="mt-15" onClick={() => this.onCreateRow()}>Adicionar nova faixa</Button>
						</div>
					)
				}
			</Fragment>
		);
	}
}

export default DayTable;
