import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import axios from 'axios'
import moment from 'moment'
import {
  Col,
  Form,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
  Input
} from 'antd'

import {
  equipmentsService,
  companiesService,
  departmentsService,
  employeesService,
} from './../../redux/services'

import { UIDrawerForm } from './../../components'

import ADV from './components/edit/ADV'
import IDClass from './components/edit/IDClass'
import IDX from './components/edit/IDX'
import Primme from './components/edit/Primme'
import IDFace from './components/edit/IDFace'

import Employees from './Employees'
import ExportEmployees from './ExportEmployees'

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`

const EQUIPAMENTS = {
  Primme: Primme,
  IDClass: IDClass,
  IDFace: IDFace,
  'Rep IDClass': IDClass,
  ADV: ADV,
  IDX: IDX,
  'Rep IDX': IDX,
};

class Edit extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      uuid: 0,
      isLoading: true,
      isSending: false,
      equipments: [],
      equipmentsIsLoading: false,
      models: [],
      brands: [],
      companies: [],
      companiesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
      model: '',
      communicationType: '',
      updateDateHour: false,
      enableTimeZone: false,
      enableSecurityConnection: false,
      enableFacialBiometrics: true,
    }

    this._axiosCancelEquipmentsToken = null
    this._axiosCancelCompaniesToken = null
    this._axiosCancelEmployeesToken = null
    this._axiosCancelDepartmentsToken = null
  }

  fetchEquipmentsFromAdmin = () => {
    if (this._axiosCancelEquipmentsToken) {
      this._axiosCancelEquipmentsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelEquipmentsToken = axios.CancelToken.source()

    this.setState({
      equipmentsIsLoading: true,
    })

    equipmentsService
      .getEquipmentsFromAdmin()
      .then((response) => {
        this.setState({
          equipmentsIsLoading: false,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          equipmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchCompanies = () => {
    if (this._axiosCancelCompaniesToken) {
      this._axiosCancelCompaniesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelCompaniesToken = axios.CancelToken.source()

    this.setState({
      companiesIsLoading: true,
    })

    companiesService
      .getAutocomplete({
        orderBy: 'nome:asc',
        ativo: 1,
        cancelToken: this._axiosCancelCompaniesToken.token,
      })
      .then((response) => {
        this.setState({
          companiesIsLoading: false,
          companies: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          companiesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchDepartments = (value) => {
    if (this._axiosCancelDepartmentsToken) {
      this._axiosCancelDepartmentsToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelDepartmentsToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        departmentsIsLoading: false,
        departments: [],
      })

      return false
    }

    this.setState({
      departmentsIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelDepartmentsToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
    }

    departmentsService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          departmentsIsLoading: false,
          departments: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          departmentsIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  fetchEmployees = (value) => {
    if (this._axiosCancelEmployeesToken) {
      this._axiosCancelEmployeesToken.cancel(
        'Only one request allowed at a time.'
      )
    }

    this._axiosCancelEmployeesToken = axios.CancelToken.source()

    if (!value.trim().length) {
      this.setState({
        employeesIsLoading: false,
        employees: [],
      })

      return false
    }

    this.setState({
      employeesIsLoading: true,
    })

    let params = {
      search: value,
      is_active: 1,
      orderBy: 'nome:asc',
      cancelToken: this._axiosCancelEmployeesToken.token,
      empresas_ids: this.form.getFieldValue('empresas').includes('todos')
        ? []
        : this.form.getFieldValue('empresas'),
      departamentos_ids: this.form
        .getFieldValue('departamentos')
        .includes('todos')
        ? []
        : this.form.getFieldValue('departamentos'),
    }

    employeesService
      .getAutocomplete({
        ...params,
      })
      .then((response) => {
        this.setState({
          employeesIsLoading: false,
          employees: response.data.data,
        })
      })
      .catch((data) => {
        if (data.error_type === API_ERRO_TYPE_CANCEL) return null

        this.setState({
          employeesIsLoading: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  onCompanyChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'empresas',
        value.filter((item) => item !== 'todos')
      )
    }

    this.setState({
      employees: [],
      employeesIsLoading: false,
      departments: [],
      departmentsIsLoading: false,
      employees: [],
      employeesIsLoading: false,
    })

    this.form.setFieldValue('departamentos', ['todos'])
    this.form.setFieldValue('funcionarios', ['todos'])
  }

  onCompanySelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('empresas', ['todos'])
    }
  }

  onDepartmentChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'departamentos',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onDepartmentSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('departamentos', ['todos'])
    }
  }

  onEmployeeChange = (value) => {
    if (value.length > 1 && value.includes('todos')) {
      this.form.setFieldValue(
        'funcionarios',
        value.filter((item) => item !== 'todos')
      )
    }
  }

  onEmployeeSelect = (value) => {
    if (value === 'todos') {
      this.form.setFieldValue('funcionarios', ['todos'])
    }
  }

  onOpen = (uuid) => {
    this.setState({
      isLoading: true,
      uuid: uuid,
    })

    if (!this.state.companies.length) {
      this.fetchCompanies()
    }
    if (!this.state.equipments.length) {
      this.fetchEquipmentsFromAdmin()
    }

    equipmentsService
      .show({ uuid })
      .then((response) => {
        const item = response.data.data

        this.setState(
          {
            isLoading: false,
            employees: item.funcionarios ?? [],
            departments: item.departamentos ?? [],
          },
          () => {
            // Fill form
            this.fillForm(item)

            this.tabEmployees && this.tabEmployees.init(item)
            this.tabExportEmployees && this.tabExportEmployees.init(item)
          }
        )
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
          onOk: () => {
            // Force close
            return this.onClose()
          },
        })
      })
  }

  fillForm = (data) => {
    const {
      marca,
      modelo,
      numero_serial_rep,
      ativo,
      ultimo_nsr,
      tipo_comunicacao,
      descricao,
      empresas,
      departamentos,
      funcionarios,
      configuracao,
    } = data

    this.setState({
      model: modelo,
      communicationType: tipo_comunicacao,
      updateDateHour: configuracao?.atualizar_data_hora,
      enableTimeZone: configuracao?.habilitar_horario_verao,
      enableSecurityConnection: configuracao?.habilitar_conexao_segura,
      enableFacialBiometrics: configuracao?.enviar_avatar_facial,
    })

    this.form.setFieldsValue({
      marca: marca,
      modelo: modelo,
      numero_serial_rep: numero_serial_rep,
      ativo: ativo,
      ultimo_nsr: ultimo_nsr,
      tipo_comunicacao: tipo_comunicacao,
      descricao: descricao,
      empresas:
        empresas.length > 0 ? empresas.map((item) => item.id) : ['todos'],
      departamentos:
        departamentos.length > 0
          ? departamentos.map((item) => item.id)
          : ['todos'],
      funcionarios:
        funcionarios.length > 0
          ? funcionarios.map((item) => item.id)
          : ['todos'],
    })

    // IDClass
    if (modelo === 'IDClass') {
      this.form.setFieldsValue({
        'configuracao_idclass[ip]': configuracao.ip,
        'configuracao_idclass[porta_tcp_ip]': configuracao.porta_tcp_ip,
        'configuracao_idclass[mascara_sub_rede]': configuracao.mascara_sub_rede,
        'configuracao_idclass[gateway]': configuracao.gateway,
        'configuracao_idclass[cpf_comunicacao]': configuracao.cpf_comunicacao,
        'configuracao_idclass[usuario]': configuracao.usuario,
        'configuracao_idclass[senha]': configuracao.senha,
        'configuracao_idclass[tipo_documento]': configuracao.tipo_documento,
        'configuracao_idclass[fuso_horario]': configuracao.fuso_horario,
        'configuracao_idclass[atualizar_data_hora]': Boolean(
          parseInt(configuracao?.atualizar_data_hora)
        ),
        'configuracao_idclass[data_hora]':
          configuracao.data_hora && moment(configuracao.data_hora),
        'configuracao_idclass[digito_barras]': configuracao.digito_barras,
        'configuracao_idclass[formato_rfid]': configuracao.formato_rfid,
        'configuracao_idclass[habilitar_horario_verao]': Boolean(
          parseInt(configuracao.habilitar_horario_verao)
        ),
        'configuracao_idclass[inicio_horario_verao]':
          configuracao.inicio_horario_verao &&
          moment(configuracao.inicio_horario_verao),
        'configuracao_idclass[fim_horario_verao]':
          configuracao.fim_horario_verao &&
          moment(configuracao.fim_horario_verao),
      })
    }

    // IDFace
    if (modelo === 'IDFace') {
      this.form.setFieldsValue({
        'configuracao_idface[ip]': configuracao.ip,
        'configuracao_idface[porta_tcp_ip]': configuracao.porta_tcp_ip,
        'configuracao_idface[mascara_sub_rede]': configuracao.mascara_sub_rede,
        'configuracao_idface[gateway]': configuracao.gateway,
        'configuracao_idface[usuario]': configuracao.usuario,
        'configuracao_idface[senha]': configuracao.senha,
        'configuracao_idface[tipo_documento]': configuracao.tipo_documento,
        'configuracao_idface[fuso_horario]': configuracao.fuso_horario,
        'configuracao_idface[atualizar_data_hora]': Boolean(
          parseInt(configuracao?.atualizar_data_hora)
        ),
        'configuracao_idface[data_hora]':
          configuracao.data_hora && moment(configuracao.data_hora),
        'configuracao_idface[enviar_avatar_facial]': Boolean(
            parseInt(configuracao?.enviar_avatar_facial)
        ),
      })
    }

    // Primme
    if (modelo === 'Primme') {
      this.form.setFieldsValue({
        'configuracao_primme[senha_menu]': configuracao.senha_menu,
        'configuracao_primme[ip]': configuracao.ip,
        'configuracao_primme[modo_tcp_ip]': configuracao.modo_tcp_ip,
        'configuracao_primme[ip_servidor]': configuracao.ip_servidor,
        'configuracao_primme[porta_tcp_ip]': configuracao.porta_tcp_ip,
        'configuracao_primme[mascara_sub_rede]': configuracao.mascara_sub_rede,
        'configuracao_primme[gateway]': configuracao.gateway,
        'configuracao_primme[dns]': configuracao.dns,
        'configuracao_primme[mac]': configuracao.mac,
        'configuracao_primme[desativar_icmp]': configuracao.desativar_icmp,
        'configuracao_primme[gravar_comunicacao_log]':
          configuracao.gravar_comunicacao_log,
        'configuracao_primme[quantidade_tentativa]':
          configuracao.quantidade_tentativa,
        'configuracao_primme[reconectar_apos_tentativa]':
          configuracao.reconectar_apos_tentativa,
        'configuracao_primme[tempo_entre_tentativa]':
          configuracao.tempo_entre_tentativa?.substring(0, 5),
        'configuracao_primme[habilitar_conexao_segura]':
          configuracao.habilitar_conexao_segura,
        'configuracao_primme[ip_conexao_segura]':
          configuracao.ip_conexao_segura,
        'configuracao_primme[tipo_leitora]': configuracao.tipo_leitora,
        'configuracao_primme[tipo_documento]': configuracao.tipo_documento,
        'configuracao_primme[formato_cpf]': configuracao.formato_cpf,
        'configuracao_primme[tipo_corte]': configuracao.tipo_corte,
        'configuracao_primme[modulo_biometrico]':
          configuracao.modulo_biometrico,
        'configuracao_primme[fuso_horario]': configuracao.fuso_horario,
        'configuracao_primme[habilitar_horario_verao]': Boolean(
          parseInt(configuracao.habilitar_horario_verao)
        ),
        'configuracao_primme[atualizar_data_hora]': Boolean(
          parseInt(configuracao?.atualizar_data_hora)
        ),
        'configuracao_primme[data_hora]':
          configuracao.data_hora && moment(configuracao.data_hora),
        'configuracao_primme[inicio_horario_verao]':
          configuracao.inicio_horario_verao &&
          moment(configuracao.inicio_horario_verao),
        'configuracao_primme[fim_horario_verao]':
          configuracao.fim_horario_verao &&
          moment(configuracao.fim_horario_verao),
      })
    }

    // ADV
    if (modelo === 'ADV') {
      const tempo_entre_tentativa = typeof configuracao.tempo_entre_tentativa === 'string' ? 
        configuracao?.tempo_entre_tentativa?.substring(0, 5) :
        configuracao?.tempo_entre_tentativa;

      this.form.setFieldsValue({
        'configuracao_adv[senha_menu]': configuracao.senha_menu,
        'configuracao_adv[ip]': configuracao.ip,
        'configuracao_adv[modo_tcp_ip]': configuracao.modo_tcp_ip,
        'configuracao_adv[ip_servidor]': configuracao.ip_servidor,
        'configuracao_adv[porta_tcp_ip]': configuracao.porta_tcp_ip,
        'configuracao_adv[mascara_sub_rede]': configuracao.mascara_sub_rede,
        'configuracao_adv[gateway]': configuracao.gateway,
        'configuracao_adv[dns]': configuracao.dns,
        'configuracao_adv[mac]': configuracao.mac,
        'configuracao_adv[desativar_icmp]': configuracao.desativar_icmp,
        'configuracao_adv[gravar_comunicacao_log]':
          configuracao.gravar_comunicacao_log,
        'configuracao_adv[quantidade_tentativa]':
          configuracao.quantidade_tentativa,
        'configuracao_adv[reconectar_apos_tentativa]':
          configuracao.reconectar_apos_tentativa,
        'configuracao_adv[tempo_entre_tentativa]': tempo_entre_tentativa,
        'configuracao_adv[habilitar_conexao_segura]':
          configuracao.habilitar_conexao_segura,
        'configuracao_adv[ip_conexao_segura]': configuracao.ip_conexao_segura,
        'configuracao_adv[usuario]': configuracao.usuario,
        'configuracao_adv[senha]': configuracao.senha,
        'configuracao_adv[tipo_leitora]': configuracao.tipo_leitora,
        'configuracao_adv[tipo_documento]': configuracao.tipo_documento,
        'configuracao_adv[formato_cpf]': configuracao.formato_cpf,
        'configuracao_adv[tipo_corte]': configuracao.tipo_corte,
        'configuracao_adv[modulo_biometrico]': configuracao.modulo_biometrico,
        'configuracao_adv[fuso_horario]': configuracao.fuso_horario,
        'configuracao_adv[habilitar_horario_verao]': Boolean(
          parseInt(configuracao.habilitar_horario_verao)
        ),
        'configuracao_adv[atualizar_data_hora]': Boolean(
          parseInt(configuracao?.atualizar_data_hora)
        ),
        'configuracao_adv[data_hora]':
          configuracao.data_hora && moment(configuracao.data_hora),
        'configuracao_adv[inicio_horario_verao]':
          configuracao.inicio_horario_verao &&
          moment(configuracao.inicio_horario_verao),
        'configuracao_adv[fim_horario_verao]':
          configuracao.fim_horario_verao &&
          moment(configuracao.fim_horario_verao),
      })
    }

    // IDX
    if (['IDX', 'Rep IDX'].includes(modelo)) {
      this.form.setFieldsValue({
        'configuracao_idx[ip]': configuracao.ip,
        'configuracao_idx[porta_tcp_ip]': configuracao.porta_tcp_ip,
        'configuracao_idx[chaver_comunicacao]': configuracao.chaver_comunicacao,
        'configuracao_idx[digito_barras]': configuracao.digito_barras,
        'configuracao_idx[gateway]': configuracao.gateway,
        'configuracao_idx[tipo_documento]': configuracao.tipo_documento,
        'configuracao_idx[mascara_sub_rede]': configuracao.mascara_sub_rede,
        'configuracao_idx[fuso_horario]': configuracao.fuso_horario,
        'configuracao_idx[atualizar_data_hora]': Boolean(
          parseInt(configuracao?.atualizar_data_hora)
        ),
        'configuracao_idx[data_hora]':
          configuracao.data_hora && moment(configuracao.data_hora),
        'configuracao_idx[inicio_horario_verao]':
          configuracao.inicio_horario_verao &&
          moment(configuracao.inicio_horario_verao),
        'configuracao_idx[fim_horario_verao]':
          configuracao.fim_horario_verao &&
          moment(configuracao.fim_horario_verao),
      })
    }
  }

  resetFields = () => {
    this.form.resetFields()
  }

  onClose = () => {
    // Reset fields
    this.resetFields()

    // Callback
    this.props.onClose()
  }

  onFinish = (values) => {
    this.setState({
      isSending: true,
    })

    const { uuid } = this.state

    const data = { ...values }

    // uuid
    data.uuid = uuid

    if (!(data.empresas instanceof Array)) {
      data.empresas = [data.empresas]
    }

    if (!(data.departamentos instanceof Array)) {
      data.departamentos = [data.departamentos]
    }

    if (!(data.funcionarios instanceof Array)) {
      data.funcionarios = [data.funcionarios]
    }

    if (data.empresas.includes('todos')) {
      data.empresas = null
    }

    if (data.departamentos.includes('todos')) {
      data.departamentos = null
    }

    if (data.funcionarios.includes('todos')) {
      data.funcionarios = null
    }

    const modelo = data.modelo

    if (modelo === 'IDClass') {
      if (!!data['configuracao_idclass[data_hora]']) {
        data['configuracao_idclass[data_hora]'] = moment(
          data['configuracao_idclass[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }

      if (
        !!data['configuracao_idclass[inicio_horario_verao]'] &&
        !!data['configuracao_idclass[fim_horario_verao]']
      ) {
        data['configuracao_idclass[inicio_horario_verao]'] = moment(
          data['configuracao_idclass[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_idclass[fim_horario_verao]'] = moment(
          data['configuracao_idclass[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }
    }

    if (modelo === 'IDFace') {
      if (!!data['configuracao_idface[data_hora]']) {
        data['configuracao_idface[data_hora]'] = moment(
          data['configuracao_idface[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }
    }

    if (modelo === 'Primme') {
      if (!!data['configuracao_primme[data_hora]']) {
        data['configuracao_primme[data_hora]'] = moment(
          data['configuracao_primme[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }

      if (
        !!data['configuracao_primme[inicio_horario_verao]'] &&
        !!data['configuracao_primme[fim_horario_verao]']
      ) {
        data['configuracao_primme[inicio_horario_verao]'] = moment(
          data['configuracao_primme[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_primme[fim_horario_verao]'] = moment(
          data['configuracao_primme[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }
    }

    if (modelo === 'ADV') {
      if (!!data['configuracao_adv[data_hora]']) {
        data['configuracao_adv[data_hora]'] = moment(
          data['configuracao_adv[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }

      if (
        !!data['configuracao_adv[inicio_horario_verao]'] &&
        !!data['configuracao_adv[fim_horario_verao]']
      ) {
        data['configuracao_adv[inicio_horario_verao]'] = moment(
          data['configuracao_adv[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_adv[fim_horario_verao]'] = moment(
          data['configuracao_adv[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }

      // data['configuracao_adv[tempo_entre_tentativa]'] = data['configuracao_adv[tempo_entre_tentativa]'] + ':00'
    }

    if (modelo === 'IDX') {
      if (!!data['configuracao_idx[data_hora]']) {
        data['configuracao_idx[data_hora]'] = moment(
          data['configuracao_idx[data_hora]']
        ).format('YYYY-MM-DD HH:mm:ss')
      }

      if (
        !!data['configuracao_idx[inicio_horario_verao]'] &&
        !!data['configuracao_idx[fim_horario_verao]']
      ) {
        data['configuracao_idx[inicio_horario_verao]'] = moment(
          data['configuracao_idx[inicio_horario_verao]']
        ).format('YYYY-MM-DD')
        data['configuracao_idx[fim_horario_verao]'] = moment(
          data['configuracao_idx[fim_horario_verao]']
        ).format('YYYY-MM-DD')
      }
    }

    equipmentsService
      .edit(data)
      .then((response) => {
        this.setState({
          isSending: false,
        })

        // Success message
        message.success('Registro atualizado com sucesso.')

        // Callback
        this.props.onComplete()
      })
      .catch((data) => {
        this.setState({
          isSending: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  handleClearDateTime = () => {
    const { model } = this.state

    if (model === 'IDClass') {
      this.form.setFieldsValue({
        'configuracao_idclass[data_hora]': '',
      })
    }

    if (model === 'IDFace') {
      this.form.setFieldsValue({
        'configuracao_idface[data_hora]': '',
      })
    }

    if (model === 'Primme') {
      this.form.setFieldsValue({
        'configuracao_primme[data_hora]': '',
      })
    }

    if (model === 'ADV') {
      this.form.setFieldsValue({
        'configuracao_adv[data_hora]': '',
      })
    }

    if (model === 'IDX') {
      this.form.setFieldsValue({
        'configuracao_idx[data_hora]': '',
      })
    }
  }

  handleClearTimeZones = () => {
    const { model } = this.state

    if (model === 'IDClass') {
      this.form.setFieldsValue({
        'configuracao_idclass[inicio_horario_verao]': '',
        'configuracao_idclass[fim_horario_verao]': '',
      })
    }

    if (model === 'Primme') {
      this.form.setFieldsValue({
        'configuracao_primme[inicio_horario_verao]': '',
        'configuracao_primme[fim_horario_verao]': '',
      })
    }

    if (model === 'ADV') {
      this.form.setFieldsValue({
        'configuracao_adv[inicio_horario_verao]': '',
        'configuracao_adv[fim_horario_verao]': '',
      })
    }

    if (model === 'IDX') {
      this.form.setFieldsValue({
        'configuracao_idx[inicio_horario_verao]': '',
        'configuracao_idx[fim_horario_verao]': '',
      })
    }
  }

  render() {
    const { visible, defaultActiveKey = "general" } = this.props

    const {
      isLoading,
      isSending,
      equipmentsIsLoading,
      models,
      brands,
      companies,
      companiesIsLoading,
      departments,
      employees,
      employeesIsLoading,
      model,
      communicationType,
      updateDateHour,
      enableTimeZone,
      enableSecurityConnection,
      enableFacialBiometrics,
    } = this.state

    const EquipamentComponent = EQUIPAMENTS[model];

    return (
      <UIDrawerForm
        visible={visible}
        width={800}
        onClose={this.onClose}
        isLoading={isLoading}
        isSending={isSending}
        formId={formId}
        title={`Editar Equipamento`}
      >
        <Form
          ref={(el) => (this.form = el)}
          id={formId}
          layout="vertical"
          scrollToFirstError
          onFinish={this.onFinish}
          autoComplete="nope"
        >
          <Tabs defaultActiveKey={defaultActiveKey}>
            <Tabs.TabPane forceRender tab="Info. Gerais" key="general">
              <Row gutter={16}>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="marca"
                    label="Marca"
                    hasFeedback
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      ref={(el) => (this.modelo = el)}
                      filterOption={false}
                      allowClear
                      placeholder="Selecione a marca"
                      notFoundContent={
                        equipmentsIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      showSearch
                      disabled
                    >
                      {brands.map((item, index) => (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="modelo"
                    label="Modelo"
                    hasFeedback
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      ref={(el) => (this.modelo = el)}
                      filterOption={false}
                      allowClear
                      placeholder="Selecione o modelo"
                      notFoundContent={
                        equipmentsIsLoading ? (
                          <Spin
                            indicator={
                              <i className="fad fa-spinner-third fa-spin" />
                            }
                          />
                        ) : null
                      }
                      showSearch
                      disabled
                    >
                      {models.map((item, index) => (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="tipo_comunicacao"
                    label="Comunicação"
                    rules={[{ required: true, message: 'Campo obrigatório.' }]}
                  >
                    <Select
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (typeof option.children === 'string'
                          ? option.children
                          : option.children.props.children
                        )
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        this.setState({ communicationType: value })
                      }
                      allowClear
                      placeholder="Selecione o tipo de comunicação"
                      showSearch

                      // disabled={['IDX', 'Rep IDX', 'ADV', 'Primme'].includes(model)}
                    >
                      <Select.Option value="tcp_ip">TCP/IP</Select.Option>
                      <Select.Option value="pendrive">Pendrive</Select.Option>
                      {model === 'ADV' && (
                        <Select.Option value="wifi">WiFi</Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col xs={24} md={24}>
                <Form.Item
                  name="descricao"
                  label="Descrição"
                  hasFeedback
                  rules={[{ required: true, message: 'Campo obrigatório.' }]}
                >
                  <Input placeholder="Descrição" />
                </Form.Item>
              </Col>
              <Form.Item
                name="empresas"
                label="Empresa"
                rules={[{ required: true, message: 'Campo obrigatório.' }]}
              >
                <Select
                  ref={(el) => (this.empresas = el)}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (typeof option.children === 'string'
                      ? option.children
                      : option.children.props.children
                    )
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  placeholder="Selecione a(s) empresa(s)"
                  notFoundContent={
                    companiesIsLoading ? (
                      <Spin
                        indicator={
                          <i className="fad fa-spinner-third fa-spin" />
                        }
                      />
                    ) : null
                  }
                  onChange={this.onCompanyChange}
                  onSelect={this.onCompanySelect}
                  showSearch
                >
                  {companies.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="departamentos"
                label="Departamento"
                rules={[{ required: true, message: 'Campo obrigatório.' }]}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (typeof option.children === 'string'
                      ? option.children
                      : option.children.props.children
                    )
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  placeholder="Selecione o(s) departamento(s)"
                  notFoundContent={
                    employeesIsLoading ? (
                      <Spin
                        indicator={
                          <i className="fad fa-spinner-third fa-spin" />
                        }
                      />
                    ) : null
                  }
                  onSearch={this.fetchDepartments}
                  onChange={this.onDepartmentChange}
                  onSelect={this.onDepartmentSelect}
                  showSearch
                >
                  <Select.Option value="todos">Todos</Select.Option>
                  {departments.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="funcionarios"
                label="Funcionário"
                rules={[{ required: true, message: 'Campo obrigatório.' }]}
              >
                <Select
                  mode="multiple"
                  filterOption={false}
                  allowClear
                  placeholder="Selecione o(s) funcionário(s)"
                  notFoundContent={
                    employeesIsLoading ? (
                      <Spin
                        indicator={
                          <i className="fad fa-spinner-third fa-spin" />
                        }
                      />
                    ) : null
                  }
                  onSearch={this.fetchEmployees}
                  onChange={this.onEmployeeChange}
                  onSelect={this.onEmployeeSelect}
                  showSearch
                >
                  <Select.Option value="todos">Todos</Select.Option>
                  {employees.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="ativo" label="Ativo" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Tabs.TabPane>

            <Tabs.TabPane forceRender tab="Configurações" key="config">
            {EquipamentComponent && (
                <EquipamentComponent
                  communicationType={communicationType}
                  item={{
                    updateDateHour,
                    enableTimeZone,
                    enableSecurityConnection,
                    enableFacialBiometrics,
                  }}
                  handleClearDateTime={this.handleClearDateTime}
                  handleClearTimeZones={this.handleClearTimeZones}
                />
              )}
            </Tabs.TabPane>

            <Tabs.TabPane forceRender tab="Funcionários" key="employees">
              <Employees ref={(el) => (this.tabEmployees = el)} />
            </Tabs.TabPane>
            <Tabs.TabPane
              forceRender
              tab="Exportar Funcionários"
              key="export-employees"
              disabled={communicationType !== 'pendrive' ? true : false}
            >
              <ExportEmployees ref={(el) => (this.tabExportEmployees = el)} />
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </UIDrawerForm>
    )
  }
}

export default Edit
