import React, { Component, Fragment } from "react";
import { Col, Modal, Spin, Row, Empty, Button } from "antd";
import moment from "moment";
import qs from 'querystring'
import { connect } from "react-redux";
import { reportService } from "./../../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			data_inicio: "",
			data_fim: "",
			data: [],
			tableData: [],

			columnsList: [],

			dataDia: "",
			empresa_nome: "",
			empresa_id: 0,
			groupBy: 0,
			justifications: [],
		};
	}

	componentDidMount() {
		document.body.classList.add('page-print', 'page-print-espelho-ponto');
		this.fetchGetAll();
	}

	componentWillUnmount() {
		document.body.classList.remove('page-print', 'page-print-espelho-ponto');
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		const params = qs.parse(this.props.location.search.replace('?', ''));
		const data = {};

		if (params?.empresas && params?.empresas !== 'todos') {
			this.setState({
				empresa_id: params?.empresas
			});

			data.empresas = params?.empresas;
			data.empresas = Array.isArray(params?.empresas)
				? params?.empresas
				: [params?.empresas];
		}

		if (params?.departamentos && params?.departamentos !== 'todos') {
			data.departamentos = Array.isArray(params?.departamentos)
			? params?.departamentos
			: [params?.departamentos];
		}

		if (params?.funcionarios && params?.funcionarios !== 'todos') {
			data.funcionarios = Array.isArray(params?.funcionarios)
			? params?.funcionarios
			: [params?.funcionarios];
		}

		if (params?.jornadas && params?.jornadas !== 'todos') {
			data.jornadas = Array.isArray(params?.jornadas)
			? params?.jornadas
			: [params?.jornadas];
		}

		if (params?.justificativas && params?.justificativas !== 'todos') {
			data.justificativas = Array.isArray(params?.justificativas)
			? params?.justificativas
			: [params?.justificativas];
		}

		if (params?.data_inicio) {
			data.data_inicio = params?.data_inicio;
			this.setState({
				data_inicio: params?.data_inicio,
			});
		} else {
			Modal.error({
				title: 'Ocorreu um erro!',
				content: 'Data inicial não encontrada!',
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					window.close();
				},
			});
			return false;
		}
		
		if (params?.data_fim) {
			data.data_fim = params?.data_fim;
			this.setState({
				data_fim: params?.data_fim,
			});
		} else {
			Modal.error({
				title: 'Ocorreu um erro!',
				content: 'Data final não encontrada!',
				maskClosable: false,
				keyboard: false,
				closable: false,
				onOk: () => {
					window.close();
				},
			});
			return false;
		}
		
		if (params?.includeInactiveEmployees) {
			data.includeInactiveEmployees = params?.includeInactiveEmployees;
		}

		if (params?.includePartialJustifications) {
			data.includePartialJustifications = params?.includePartialJustifications;
		}
		
		if (params?.groupBy) {
			this.setState({
				groupBy: params?.groupBy
			});
			data.groupBy = params?.groupBy;
		}

		reportService
			.occurrences(data)
			.then((response) => {
				this.setState({
					isLoading: false,
					data: response?.data?.lista,
					tableData: response.data
				});

				document.title = 'Ocorrências';

				setTimeout(() => {
					window.print();
				}, 1000); 
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	getHeaders = (groupBy) => {
		let list = [];
		switch (groupBy) {
			case '0':
			case '1':
				list = ['Dia', 'Folha', 'Funcionário', 'Ent. 1', 'Saí. 1', 'Ent. 2', 'Saí. 2', 'Ent. 3', 'Saí. 3'];
				break;
			default:
				break;
		}
		return list;
	};   

	getJustifications(listData) {
		if (!listData) return {};

		let justificativas = new Set();

		listData.forEach(item => {
			if (item.justificativa) {
				justificativas.add(item.justificativa);
			}
		});

		let justificativasArray = Array.from(justificativas);
		let justificativasString = justificativasArray.join(', ');

		return justificativasString;
	};
	
	groupByJustification = (listData) => {
		if (!listData) return {};
		const grouped = listData.reduce((acc, item) => {
			const justificativa = item.justificativa || '';
			if (!acc[justificativa]) {
				acc[justificativa] = [];
			}
			acc[justificativa].push(item);
			return acc;
		}, {});
	
		return grouped;
	}

	isTimeFormat = (value) => {
		return moment(value, 'HH:mm:ss', true).isValid();
	};
	
	formatTime = (value) => {
		if (this.isTimeFormat(value)) {
			return moment(value, 'HH:mm:ss').format('HH:mm');
		}
		return value;
	};

	render() {
    const {
			isLoading,
			data,
			groupBy,
    } = this.state;

    const { user } = this.props;
    const { name: userName } = user || {};
    const list = this.getHeaders(groupBy);
    const listData = data;

		const justificationsName = this.getJustifications(listData);
		const groupedData = this.groupByJustification(listData) || {};

    const hasPartialJustifications = listData.some((item) => item?.justParcial);
    if (hasPartialJustifications) {
			list.push("Justificativa Parcial");
    }

    const style = `@page { size: A4 landscape; margin: 0 }`;
    const TimeLogFields = ['ent1', 'sai1', 'ent2', 'sai2', 'ent3', 'sai3'];

    return (
			<Fragment>
					{isLoading ? (
							<div
									style={{
											position: 'fixed',
											top: '150px',
											left: '50%',
											transform: 'translateX(-50%)',
									}}
							>
									<Spin
											spinning={isLoading}
											indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
									/>
							</div>
					) : (
							<>
								{data.length > 0 ? (
									<table className={`table-impressao`}>
										<thead>
											<tr>
												<td className="time-card">
													<header>
														<div className="logo-container">
															<div>
																<img
																	src="/images/logos/logo-complete.svg"
																	alt="ByPonto"
																	width="180"
																/>
															</div>
															<div className="logo-title">
																<div>
																		<h1>Ocorrências</h1>
																</div>
																<div style={{ gap: '5px' }}>
																		<div>
																				Período: {moment(this.state.data_inicio).format('DD/MM/YYYY')} até {moment(this.state.data_fim).format('DD/MM/YYYY')}
																		</div>
																</div>
																<div className="user-info" style={{ marginTop: '5px' }}>
																		<h4>
																				{userName} (Logado | {moment().format('DD/MM/YYYY HH:mm')})
																		</h4>
																</div>
															</div>
														</div>
														<div className="info-container mt-2rem m-1rem">
																<Row gutter={16}>
																		<Col span={12}>
																				<Row className="row-section-50">
																						<Col span={24}>
																								{groupBy === '0' ? (
																										<div
																												className="row-section-flex"
																												style={{ alignItems: 'flex-start', maxWidth: '100%' }}
																										>
																												<div style={{ fontSize: '15px' }}>
																														<strong>Justificativa(s):</strong>
																												</div>
																												<div style={{ fontSize: '14px' }}>{this.getJustifications(listData)}</div>
																										</div>
																								) : (
																										<></>
																								)}
																						</Col>
																				</Row>
																		</Col>
																</Row>
														</div>
													</header>
												</td>
											</tr>
										</thead>
										<tbody>
											{groupBy !== '1' && (
													<tr>
														<td>
															<main>
																<div
																	className="table-container mt-2rem m-1rem"
																	style={{ margin: '20px' }}
																>
																	<table className="responsive-table">
																		<thead>
																			<tr>
																				{list.map((col, index) => (
																					<th
																						key={index}
																						style={{
																							width:
																								col === 'Funcionário'
																									? '260px'
																									: col === 'Justificativa Parcial'
																									? '150px'
																									: index === list.length - 1
																									? 'auto'
																									: col === 'Folha'
																									? '50px'
																									: '90px',
																						}}
																					>
																						{col}
																					</th>
																				))}
																			</tr>
																		</thead>
																		<tbody>
																			{listData.map((item, index) => (
																				<tr key={index}>
																					{groupBy === '0' ? (
																						<>
																							<td>{item?.dia}</td>
																							<td style={{ marginLeft: '5px' }}>{item?.folha}</td>
																							<td>{item?.funcionario}</td>
																							{TimeLogFields.map((field, idx) => {
																								const value = item?.[field];
																								const formattedValue = this.formatTime(value);
																								return (
																									<td key={idx} style={{ marginLeft: '5px' }}>
																										{formattedValue}
																									</td>
																								);
																							})}
																							{hasPartialJustifications && (
																								<td>
																									{item?.justParcial
																										? `${item.justificativa} ${moment(item.duracao, 'HH:mm:ss').format('HH:mm')}`
																										: ''}
																								</td>
																							)}
																						</>
																					) : (
																						<></>
																					)}
																				</tr>
																			))}
																		</tbody>
																	</table>
																</div>
															</main>
														</td>
													</tr>
												)}
											</tbody>
											{groupBy === '1' && groupedData && Object.entries(groupedData).length > 0 ? (
												Object.entries(groupedData).map(([justification, items]) => (
													<Fragment key={justification}>
														<div className="groupby">
															<b>Justificativa</b>: {justification || "Sem justificativa"}
														</div>
														<div className="table-container mt-2rem m-1rem groupby-table">
															<table className="responsive-table">
																<thead>
																	<tr>
																		{list.map((col, index) => (
																			<th
																				key={index}
																				style={{
																					width:
																						col === "Funcionário"
																							? "260px"
																							: col === "Justificativa Parcial"
																							? "150px"
																							: index === list.length - 1
																							? "auto"
																							: col === "Folha"
																							? "50px"
																							: "90px",
																				}}
																			>
																				{col}
																			</th>
																		))}
																	</tr>
																</thead>
																<tbody>
																	{items.map((item, index) => (
																		<tr key={index}>
																			<td>{item?.dia}</td>
																			<td>{item?.folha}</td>
																			<td>{item?.funcionario}</td>
																			{TimeLogFields.map((field, idx) => {
																				const value = item?.[field];
																				const formattedValue = this.formatTime(value);
																				return (
																					<td key={idx} style={{ marginLeft: "5px" }}>
																						{formattedValue}
																					</td>
																				);
																			})}
																			{hasPartialJustifications && (
																				<td>
																					{item?.justParcial
																						? `${item.justificativa} ${moment(item.duracao, "HH:mm:ss").format("HH:mm")}`
																						: ""}
																				</td>
																			)}
																		</tr>
																	))}
																</tbody>
															</table>
														</div>
													</Fragment>
												))
											) : null}
											<tfoot>
												<tr>
													<td>
														<div className="footer-space">&nbsp;</div>
													</td>
												</tr>
											</tfoot>
										</table>
								) : (
										<Empty
											description="Nenhum registro encontrado com esses parâmetros."
											style={{
												padding: '20px',
												position: 'absolute',
												top: '20px',
												left: '50%',
												transform: 'translateX(-50%)',
											}}
										>
											<Button type="primary" onClick={() => window.close()}>
												Voltar
											</Button>
										</Empty>
								)}
						</>
					)}
					<style dangerouslySetInnerHTML={{ __html: style }} />
			</Fragment>
    );
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userData,
	};
};

export default connect(mapStateToProps)(Index);
