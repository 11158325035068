import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";

import { Row, Col, Button, Modal, message } from "antd";

import moment from "moment";

import { timeCardService } from "./../../../redux/services";

import ModalAjustes from "../ModalAjustes";
import ModalActions from "../ModalActions";
import ModalDetails from "../ModalDetails";

class TableRow extends Component {
	static propTypes = {
		columns: PropTypes.arrayOf(
			PropTypes.shape({
				title    : PropTypes.string,
				className: PropTypes.string,
				width    : PropTypes.any,
				visible  : PropTypes.bool,
				render   : PropTypes.func.isRequired,
			}),
		),
		item: PropTypes.object,
		index: PropTypes.number,
		updateFuncionarioRow: PropTypes.func.isRequired,
		fetchGetAll: PropTypes.func,
	}

	constructor(props) {
		super(props);

		this.state = {
			ajustesModalVisible: false,
			actionsModalVisible: false,
			detailsModalVisible: false,
		};
	}

	actionsOpen = () => {
		this.setState({ actionsModalVisible: true });

		// On open screen
		this.actionsScreen.onOpen();
	};

	actionsOnClose = () => {
		this.setState({ actionsModalVisible: false });
	}

	actionsOnComplete = (item) => {
		this.setState({ actionsModalVisible: false });
		this.props.updateFuncionarioRow(this.props.index, item.funcionario_id);
	}

	detailsOpen = () => {
		this.setState({ detailsModalVisible: true });

		// On open screen
		this.detailsScreen.onOpen();
	};

	detailsOnClose = () => {
		this.setState({ detailsModalVisible: false });
	}

	detailsOnComplete = () => {
		this.setState({ detailsModalVisible: false });
	}

	ajustesOpen = () => {
		this.setState({ ajustesModalVisible: true });

		// On open screen
		this.ajustesScreen.onOpen();
	};

	ajustesOnClose = () => {
		this.setState({ ajustesModalVisible: false });
	}

	ajustesOnComplete = () => {
		this.setState({ ajustesModalVisible: false });
	}

	restartOpen = () => {
		if (this.props.item?.status !== 'execao') {
			return this.confirmRestart(false);
		}

		Modal.confirm({
			title: `Deseja manter as exceções caso houver?`,
			content: "Esta ação não poderá ser desfeita.",
			okText: "Sim",
			cancelText: "Não",
			centered: true,
			onCancel: () => {
				this.confirmRestart(false);
			},
			onOk: () => {
				this.confirmRestart(true);
			},
		});		
	};

	confirmRestart = (manter_jornada_original) => {
		Modal.confirm({
			title: `Deseja voltar os dados originais para esse dia [${moment(this.props.dataDia).format("DD/MM/YYYY")}]?`,
			content: "Esta ação não poderá ser desfeita.",
			okText: "Sim",
			cancelText: "Não",
			centered: true,
			onCancel: () => {
				document.querySelector(".ant-modal-wrap").focus();
			},
			onOk: () => {
				return this.restartOnComplete(manter_jornada_original);
			},
		});
	}

	restartOnComplete = (manter_jornada_original = false) => {
		return timeCardService
			.restart({
				cartao_ponto_id: this.props.item.id,
				manter_jornada_original
			})
			.then((response) => {
				this.props.updateFuncionarioRow(this.props.index, this.props.item.funcionario_id);
				this.actionsOnClose();

				// Success message
				message.success('Os dados originais voltaram com sucesso.');
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	}

	getBackgroundColorForStatus(status, rowIndex) {
		switch (status) {
			case 'folga':
				return '#94EDF9';
			case 'feriado':
				return '#8DB1FA';
			case 'dia_especial':
				return '#FA97CB';
			case 'afastamento':
				return '#FED58B';
			case 'justificativa_parcial':
				return 'rgb(81,0,202, 0.35)';
			case 'dia_todo':
				return 'rgb(250, 200, 255, 0.35)';
			default:
				return rowIndex % 2 === 0 ? '#EEEEEE' : '#FFFFFF';
		}
	}

	render() {
		const { columns, item, index } = this.props;

		return (
			<div className="list-items-item">
			<div className="row" style={{ backgroundColor: this.getBackgroundColorForStatus(item.status, index) }}>
				{columns.map((column, col_index) => {
					if (!column || typeof column.render !== 'function') return null;
						const isLastColumn = col_index === columns.length - 1;
						const key = column.key || column.title;

						const columnProps = {
							key: col_index,
							className: `col ${column.className || ""} ${isLastColumn ? 'last-column-timeCard' : ''}`,
							style: {
								width: column.width || 'auto',
								minWidth: column.width || 'auto',
								maxWidth: column.width || 'auto',
							},
						};

						if (column.title === "Ações") {
							return (
								<div data-title={column.title} {...columnProps} key={`${key}-${col_index}`}>
									<Row gutter={5} justify="center">
										<Col>
											<Button
												type="secondary"
												shape="circle"
												icon={<i className="fas fa-cog"></i>}
												onClick={this.actionsOpen}
											/>
										</Col>
									</Row>
								</div>
							);
						} else {
							return (
								<div data-title={column.title} {...columnProps} key={`${key}-${col_index}`}>
									<Fragment>{column.render(item, index)}</Fragment>
								</div>
							);
					}
					})}
				</div>
				<ModalDetails
					ref={el => this.detailsScreen = el}
					visible={this.state.detailsModalVisible}
					onComplete={this.detailsOnComplete}
					onClose={this.detailsOnClose}
					empresa={item?.contrato?.empresa?.nome}
					funcionario={item?.funcionario?.nome}
					dataDia={this.props.dataDia}
					cartaoPontoId={item?.id}
				/>
				<ModalActions
					ref={el => this.actionsScreen = el}
					visible={this.state.actionsModalVisible}
					onComplete={this.actionsOnComplete}
					onClose={this.actionsOnClose}
					restartOpen={this.restartOpen}
					detailsOpen={this.detailsOpen}
					newJoinColumn={() => { this.props.newJoinColumn(), this.actionsOnClose() }}
					removeBatida={() => { this.props.removeBatida(), this.actionsOnClose() }}
					journeyOpen={this.props.journeyOpen}
					dataDia={this.props.dataDia}
					empresa={item?.contrato?.empresa}
					departamento={item?.departamento}
					funcionario={item?.funcionario}
					cartaoPontoId={item?.id}
					tipoCalculo={item?.tipo_calculo}
					item={item}
					reloadRow={this.props.reloadRow}
					fetchGetAll={this.props.fetchGetAll}
				/>
				<ModalAjustes
					ref={el => this.ajustesScreen = el}
					visible={this.state.ajustesModalVisible}
					onComplete={this.ajustesOnComplete}
					onClose={this.ajustesOnClose}
					dataDia={this.props.dataDia}
					funcionarioId={item?.funcionario?.id}
				/>
			</div>
		)
	}
}

export default TableRow;
